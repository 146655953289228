import { CalculateFontSize, CalculateFromHeight } from "styled/base/SizeFunctions";
import { CommonDescriptionFont } from "styled/common/font-styles/CommonFonts";
import styled from "styled-components";

export const WhatWeCanWrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;
  ${CalculateFromHeight("margin-top", { mobile: 8, tablet: 9, desktop: 10 })};
  ${CalculateFromHeight("margin-bottom", { mobile: 8, tablet: 9, desktop: 10 })};
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 2 / span 10;
  gap: 65px;
  ${CalculateFromHeight("margin-top", { mobile: 12, tablet: 9, desktop: 10 })};

  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    gap: 23px 0;
  }

  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-gap: 20px 0;
  }
`;

export const OffersRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 65px;

  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-template-columns: 1fr;
    grid-gap: 23px 0;
  }

  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-template-columns: 1fr;
    grid-gap: 20px 0;
  }
`;

export const OffersColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px 0;

  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    gap: 23px 0;
  }

  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    gap: 20px 0;
  }
`;

export const OfferItem = styled.p`
  ${CommonDescriptionFont}
  ${CalculateFontSize("font-size", { mobile: 3, tablet: 2.5, desktop: 2.5 })}
`;

export const BottomText = styled.p`
  ${CommonDescriptionFont}
  ${CalculateFontSize("font-size", { mobile: 3, tablet: 2.5, desktop: 2.5 })}

  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    align-self: end;
  }
`;
