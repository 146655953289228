import React from "react";

import HeaderGroup, {
  type HeaderGroupProps
} from "components/common/headers/HeaderGroup/HeaderGroup";
import Translate from "utils/translation/translation";

import {
  BottomText,
  InnerWrapper,
  OfferItem,
  OffersColumn,
  OffersRow,
  WhatWeCanWrapper
} from "./WhatWeCan.styled";

interface WhatWeCan {
  offersFirstTranslationKeys?: string[];
  offersSecondTranslationKeys?: string[];
}

const WhatWeCan: Component<WhatWeCan> = ({
  offersFirstTranslationKeys = offersFirstDefault,
  offersSecondTranslationKeys = offersSecondDefault,
  children
}) => {
  return (
    <WhatWeCanWrapper>
      <HeaderGroup {...defaultHeaderData} />
      <InnerWrapper>
        <OffersRow>
          <OffersColumn>
            {offersFirstTranslationKeys.map(text => (
              <OfferItem key={text}>{Translate(text)}</OfferItem>
            ))}
          </OffersColumn>
          <OffersColumn>
            {offersSecondTranslationKeys.map(text => (
              <OfferItem key={text}>{Translate(text)}</OfferItem>
            ))}
          </OffersColumn>
        </OffersRow>
        <BottomText>...and many many more!</BottomText>
      </InnerWrapper>

      {children}
    </WhatWeCanWrapper>
  );
};

export default WhatWeCan;

const defaultHeaderData: HeaderGroupProps = {
  instantPlayback: false,
  animationPlaybackOffset: -0.75,
  headers: [
    {
      text: "What_We",
      variant: "WhatWe",
      fontVariant: "project-header-small-font",
      id: "what-we",
      animation: { x: -100, opacity: 0, duration: 0.75, delay: 0 },
      horizontalScrollSpeed: 0.5
    },
    {
      text: "Can_Do",
      variant: "CanDo",
      fontVariant: "project-header-small-font",
      id: "can-do",
      animation: { x: 100, opacity: 0, duration: 0.75, delay: 0.125 },
      horizontalScrollSpeed: -0.5
    }
  ]
};

const offersFirstDefault = ["WhatWeCan_First", "WhatWeCan_Second", "WhatWeCan_Third"];
const offersSecondDefault = ["WhatWeCan_Fourth", "WhatWeCan_Fifth", "WhatWeCan_Sixth"];
