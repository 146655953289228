import React, { useRef } from "react";

import Advantages, { AdvantagesType } from "components/common/Advantages/Advantages";
import type { HeaderGroupProps } from "components/common/headers/HeaderGroup/HeaderGroup";
import ImagesColumn from "components/common/ImagesColumn/ImagesColumn";
import PageTitle, { type PageTitleProps } from "components/common/other/PageTitle/PageTitle";
import About from "components/common/sections/About/About";
import CallToAction from "components/common/sections/CallToAction/CallToAction";
import Services from "components/common/sections/Services/Services";
import WhyUs from "components/common/sections/WhyUs/WhyUs";
import Integrations from "components/Integrations/Integrations";
import Layout from "components/layout/Layout/Layout";
import LocomotiveElement from "components/locomotive/LocomotiveElement/LocomotiveElement";
import HomeProjects from "components/pages/home/HomeProjects/HomeProjects";
import WhatWeCan from "components/pages/web-development/WhatWeCan/WhatWeCan";
import MainCloud from "components/rendering/MainCloud/MainCloud";
import RendererWrapper from "components/rendering/RendererWrapper/RendererWrapper";
import { addCloud, useRenderContext } from "contexts/RenderContext";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Circ } from "gsap";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";
import NextJsIcon from "media/images/icons/other/nextjs-icon.png";
import ReactIcon from "media/images/icons/other/react-icon.png";
import TypescriptIcon from "media/images/icons/other/typescript-icon.png";
import type { PageProps } from "pages/index";
import { useTheme } from "styled-components";
import { GetSeoData } from "utils/seo-utils";
import Translate from "utils/translation/translation";

const WebDevelopment = ({ location }: PageProps) => {
  const {
    colors: { games, ar, turquoise, yellow }
  } = useTheme();
  const [, dispatchRender] = useRenderContext();
  const rightCloudWrapperRef = useRef<HTMLDivElement>(null);
  const leftCloudWrapperRef = useRef<HTMLDivElement>(null);
  const cloudLeft = {
    name: "VioletBlack",
    wrapper: leftCloudWrapperRef.current
  };
  const cloudRight = {
    name: "PinkViolet",
    wrapper: rightCloudWrapperRef.current
  };
  const integrationsIcons = [
    { image: ReactIcon, alt: "React icon" },
    { image: TypescriptIcon, alt: "Typescript icon" },
    { image: NextJsIcon, alt: "Next.js icon" }
  ];

  const firstServices = {
    firstService: {
      title: Translate("WebDevelopment_Service_Header_One"),
      description: Translate("WebDevelopment_Service_Description_One"),
      iconName: "InnovationIcon",
      color: ar
    },
    secondService: {
      title: Translate("WebDevelopment_Service_Header_Two"),
      description: Translate("WebDevelopment_Service_Description_Two"),
      iconName: "CreativityIcon",
      color: games
    },
    thirdService: {
      title: Translate("WebDevelopment_Service_Header_Three"),
      description: Translate("WebDevelopment_Service_Description_Three"),
      iconName: "ExperienceIcon"
    }
  };

  const secondServices = {
    firstService: {
      title: Translate("WebDevelopment_Service_Header_Four"),
      description: Translate("WebDevelopment_Service_Description_Four"),
      iconName: "ArIcon",
      color: ar
    },
    secondService: {
      title: Translate("WebDevelopment_Service_Header_Five"),
      description: Translate("WebDevelopment_Service_Description_Five"),
      iconName: "WebDevIcon",
      color: turquoise
    },
    thirdService: {
      title: Translate("WebDevelopment_Service_Header_Six"),
      description: Translate("WebDevelopment_Service_Description_Six"),
      iconName: "AutomationHorizontallyIcon",
      color: yellow
    }
  };

  useFirstRenderEffect(() => {
    dispatchRender(addCloud(cloudLeft));
    dispatchRender(addCloud(cloudRight));
  }, []);

  return (
    <>
      <GatsbySeo {...GetSeoData("WebDevelopment")} />
      <Layout location={location}>
        <MainCloud color="RedOrange" variant="MainCloudWrapper" scrollSpeed={-2} />
        <PageTitle {...pageTitleData} />
        <About headerData={aboutHeaderData} />
        <Services inAbout={false} headerData={designServicesHeaderData} {...firstServices} />
        <Services
          isSecondary
          inAbout={false}
          headerData={developmentServicesHeaderData}
          {...secondServices}
        />
        <WhatWeCan />
        <ImagesColumn imageNameOne="Aloki4" imageNameTwo="Aloki5" imageNameThree="TheLabz5">
          <LocomotiveElement variant="WebDevelopmentWhatWeCanCloud" scrollSpeed={-0.5}>
            <RendererWrapper
              elementRef={rightCloudWrapperRef}
              variant="fill-background-space"
              type="cloud"
              additionalData={"PinkViolet"}
            />
          </LocomotiveElement>
        </ImagesColumn>
        <CallToAction />
        <WhyUs>
          <LocomotiveElement variant="WebDevelopmentWhyUsCloud" scrollSpeed={-0.5}>
            <RendererWrapper
              elementRef={leftCloudWrapperRef}
              variant="fill-background-space"
              type="cloud"
              additionalData={"VioletBlack"}
            />
          </LocomotiveElement>
        </WhyUs>
        <Advantages advantages={advantages} />
        <Integrations withoutHeader withButton icons={integrationsIcons} />
        <HomeProjects />
      </Layout>
    </>
  );
};

export default WebDevelopment;

const aboutHeaderData: HeaderGroupProps = {
  headers: [
    {
      text: "WebDevelopment_About_Header",
      variant: "AboutParagraph",
      fontVariant: "about-paragraph",
      id: "about-paragraph",
      animation: { x: -100, opacity: 0, duration: 0.75, delay: 0 }
    }
  ]
};

const designServicesHeaderData: HeaderGroupProps = {
  instantPlayback: false,
  animationPlaybackOffset: -0.75,
  headers: [
    {
      text: "Common_Header_Design",
      variant: "WebDevelopmentDesignServicesHeader",
      id: "services-header-web",
      animation: { x: 100, opacity: 0, duration: 0.75, delay: 0, ease: Circ.easeOut },
      horizontalScrollSpeed: 0.75
    }
  ]
};

const developmentServicesHeaderData: HeaderGroupProps = {
  instantPlayback: false,
  animationPlaybackOffset: -0.75,
  headers: [
    {
      text: "Common_Header_Development",
      variant: "WebDevelopmentDevelopmentServicesHeader",
      id: "services-header-development",
      animation: { x: -100, opacity: 0, duration: 0.75, delay: 0, ease: Circ.easeOut },
      horizontalScrollSpeed: -0.5
    },
    {
      text: "Common_Header_Develo",
      variant: "WebDevelopmentDeveloServicesHeader",
      id: "services-header-develo",
      animation: { x: -100, opacity: 0, duration: 0.75, delay: 0, ease: Circ.easeOut },
      horizontalScrollSpeed: -0.5
    },
    {
      text: "Common_Header_Pment",
      variant: "WebDevelopmentPmentServicesHeader",
      id: "services-header-pment",
      animation: { x: -100, opacity: 0, duration: 0.75, delay: 0, ease: Circ.easeOut },
      horizontalScrollSpeed: -0.5
    }
  ]
};

const pageTitleData: PageTitleProps = {
  instantPlayback: true,
  variant: "web-development-title",
  isTitle: true,
  headers: [
    {
      text: "WebDevelopment_Header_Web",
      variant: "WebDevelopmentHeaderWeb",
      id: "web-development-web",
      title: true,
      animation: { x: -100, opacity: 0, duration: 1, delay: 0.75 },
      scrollSpeed: 1,
      horizontalScrollSpeed: 1
    },
    {
      text: "Page_Header_Development",
      variant: "WebDevelopmentHeaderDevelopment",
      id: "web-development-development",
      title: true,
      animation: { x: 100, opacity: 0, duration: 1, delay: 1 },
      scrollSpeed: 1,
      horizontalScrollSpeed: -0.5
    },
    {
      text: "Page_Header_Develo",
      variant: "WebDevelopmentHeaderDevelo",
      id: "web-development-develo",
      title: true,
      animation: { x: 100, opacity: 0, duration: 1, delay: 1 },
      scrollSpeed: 1,
      horizontalScrollSpeed: -0.5
    },
    {
      text: "Page_Header_Pment",
      variant: "WebDevelopmentHeaderPment",
      id: "web-development-pment",
      title: true,
      animation: { x: -100, opacity: 0, duration: 1, delay: 1.25 },
      scrollSpeed: 1,
      horizontalScrollSpeed: 0.5
    }
  ],
  subtitle: {
    text: "WebDevelopment_Motto",
    variant: "web-development-subtitle"
  }
};

const advantages: AdvantagesType = {
  fifth: {
    title: Translate("WebDevelopment_Advantage_Fifth_Title"),
    description: Translate("WebDevelopment_Advantage_Fifth_Description")
  }
};
